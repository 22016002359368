<template>
  <b-card class="p-0 pt-0">
    <b-card-body
        class="p-0"
    >
      <b-row
          class="align-items-center mb-1"
      >
        <b-col
            v-if="showSearch"
            class="text-right"
            md="9"
            sm="9"
            xs="9"
        >
          <b-input-group>
            <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Digite aqui para pesquisar"
            />
            <b-input-group-append>
              <b-button
                  size="sm"
                  @click="onClear"
              >
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
            v-if="!showSearch"
            md="9"
        >
          <slot name="search"/>
        </b-col>
        <b-col
            v-if="showSearch"

            :md="3"
            :sm="3"
            :xs="3"
            class="align-items-end"
            style="text-align: right"
        >{{ c }} registros
        </b-col>
      </b-row>
      <b-table
          id="i2GridTable"
          ref="i2GridTable"
          hover
          :sticky-header="height"
          v-bind="$attrs"
          :small="true"
          :no-border-collapse="true"
          :per-page="0"
          :current-page="currentPage"
          :items="itemList"
          :fields="fields"
          :filter="filter"
          show-empty
          :filter-included-fields="filterOn"
          v-on="$listeners"
          :tbody-tr-class="tbodyTrClass"
          @filtered="onFiltered"
      >
        <template #empty>
          <b-row class="text-center p-2">
            <b-col>
              Nenhum registro encontrado.
            </b-col>
          </b-row>
        </template>
        <template
            v-for="(_, slotName) of $scopedSlots"
            #[slotName]="data"
        >
          <slot
              :name="slotName"
              v-bind="data"
          />
        </template>
      </b-table>

      <b-row class="pl-1 pr-1 pt-0">
        <b-col
            md="6"
            sm="6"
            xs="6"
        >
          <b-form-group
              class="mb-0"
              style="text-align: left"
          >
            <label class="d-inline-block text-sm-left pr-1">Por página: </label>
            <b-form-select
                id="perPageSelect"
                v-model="perPage"
                :options="pageOptions"
                class="w-25"
                @change="onPerPageChange"
            />
            <label v-if="!showSearch" class="ml-1">Total de registros: {{ totalRows }}</label>
          </b-form-group>
        </b-col>
        <b-col
            md="6"
            sm="6"
            xs="6"
            style="text-align: right"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              @change="handlePageChange"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'Grid',
  components: {
    BTable,
    BCard,
    BPagination,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BFormSelect,
  },
  props: {
    fields: {
      type: [Array],
      required: true,
    },
    items: {
      type: [Array, Object],
      required: true,
    },
    newButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
    editButtonLabel: {
      type: String,
      default: 'Adicionar novo',
      required: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
      required: false,
    },
    height: {
      type: [Number, String, Boolean],
      required: false,
      default: '300px',
    },
    tbodyTrClass: {
      type: [Number, String, Boolean],
      required: false,
      default: 'table-cursor',
    }

  },
  data: () => ({
    perPage: 100,
    pageOptions: [10, 25, 50, 75, 100],
    totalRows: 0,
    currentPage: 1,
    filter: null,
    itemList: [],
    breakPoint: '',
    filterOn: [],
    isServerData: false,
  }),
  computed: {
    ...mapState(['app/windowWidth']),
  },
  watch: {
    items(data) {
      this.itemList = data.data || data
      let totalRows = this.itemList.length || 0
      if (data.meta) {
        this.isServerData = true
        totalRows = data.meta.total
        this.itemList = data.data
        this.currentPage = data.meta.current_page
      }
      this.$refs.i2GridTable.refresh()
      this.totalRows = totalRows
      setTimeout(() => {
        this.$refs.i2GridTable.refresh()
        this.$forceUpdate()
      }, 300)
      this.breakPoint = this.currentBreakPoint()
    },
    itemList() {
      this.$refs.i2GridTable.refresh()
      this.$root.$emit('bv::refresh::table', 'i2GridTable')
    },
  },
  created() {
    this.breakPoint = this.currentBreakPoint()
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'app/UPDATE_WINDOW_WIDTH') {
        this.breakPoint = this.currentBreakPoint()
      }
    })
    let totalRows = this.items.length
    this.itemList = this.items.data || this.items
    if (this.items.meta) {
      totalRows = this.items.meta.total
      this.currentPage = 1
    }
    this.totalRows = totalRows
  },
  updated() {

  },
  methods: {
    ...mapGetters('app', ['currentBreakPoint']),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handlePageChange(page) {
      const event = {
        page,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onPerPageChange() {
      const event = {
        page: this.currentPage,
        per_page: this.perPage,
      }
      this.$emit('onChangePage', event)
    },
    onClear() {
      this.filter = ''
      this.$emit('onClear')
    },
  },
}

</script>
<style>

</style>
