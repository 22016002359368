import Vue from 'vue'

const Modals = {
    async confirm(message, title, onConfirm, options) {
        const vm = new Vue()
        const h = vm.$createElement
        const hMessage = h('div', { domProps: { innerHTML: message } })
        return vm.$bvModal.msgBoxConfirm(
            [hMessage],
            {
                title,
                size: 'sm',
                autofocus:true,
                okVariant: options?.okVariant || 'primary',
                okTitle: options?.cancelTitle || 'Sim',
                cancelTitle: options?.cancelTitle || 'Não',
                cancelVariant: options?.cancelVariant || 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            },
        )
    },
}
Vue.prototype.$modals = Modals
window.modals = Modals
export default Modals
