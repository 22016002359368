<template>
  <hr :data-content="text" class="hr-text">
</template>
<script>

export default {
  name: 'STextDivider',
  props: {
    text: {
      type: String,
      required: true,
    },
  },

}
</script>

  <style scoped>
    hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
    }

    hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 1rem;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0%, -50%);
    }
  </style>
