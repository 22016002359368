const state = {
    menu: null,
}

const getters = {
    items: state => {
        return state.menu
    }
}

const mutations = {
    // eslint-disable-next-line no-shadow
    SET_USER_MENU(state, payload) {
        state.menu = payload
    },
}

const actions = {
    getMenu: async ({ commit }, user) => {

        try {
            const data = await window.http.get(`sys-menu/${user}/arvore`, { loading: false })
            if (data.error) {
                return null
            }
            commit('SET_USER_MENU', data)
            return data
        } catch (error) {
            return error
        }
    },
    setMenu: async ({ commit }, menu) => {
        commit('SET_USER_MENU', menu)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
