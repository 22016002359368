/* eslint-disable */
export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$focusValidation = function (fieldName) {
      if (!fieldName && this.$validator.errors.items.length) {
        fieldName = this.$validator.errors.items[0].field
      }
      this.$el.querySelector(`[name="${fieldName}"]`).scrollIntoView(false)
    }

    // eslint-disable-next-line func-names
    Vue.prototype.$setErrorServerValidation = function (validator, errorResponse) {
      validator.reset()
      // eslint-disable-next-line no-prototype-builtins
      if (!errorResponse.hasOwnProperty('errors')) {
        return
      }
      validator.setErrors(errorResponse.errors)
    }
  },
}
