const users = [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/views/users/UsersList.vue'),
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/users/account-settings/AccountSettings.vue'),
  },
]

export default users
