import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    isLoadingAjax: false,
    apps: [],
    current_app: {},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    isLoading: state => state.isLoadingAjax,
    currentApp: state => state.current_app,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_APPS_DATA(state, payload) {
      state.apps = payload
    },
    SET_CURRENT_APP_DATA(state, payload) {
      state.current_app = payload
    },
  },
  actions: {
    getApps: async ({ commit }) => {
      const data = await window.http.get('apps')
      if (data.error) {
        return null
      }
      commit('SET_APPS_DATA', data)
      return data
    },
    getApp: async ({ commit }, uuid) => {
      const data = await window.http.get(`apps/${uuid}`)
      if (data.error) {
        return null
      }
      commit('SET_CURRENT_APP_DATA', data)
      return data
    },
  },
}
