
import Vue from 'vue'
import moment from "moment"
const TimeUtils = {
    minutesToHours(numberOfMinutes) {
        let duration = moment.duration(numberOfMinutes, 'minutes')

        let hh = (duration.years() * (365 * 24)) +
            (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours())

        let mm = duration.minutes()

        return hh.toString().padStart(2, '0') + ':' + mm.toString().padStart(2, '0')
    }
}

Vue.prototype.$timeUtils = TimeUtils
window.timeUtils = TimeUtils
export default TimeUtils
