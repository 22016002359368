import Vue from 'vue'
import plural from 'pluralize-ptbr'

const StringUtils = {

  snakeCase(string) {
    if (!string) {
      return ''
    }
    const result = string.replace(/([A-Z])/g, ' $1').trim()
    return result.split(' ').join('_').toLowerCase()
  },
  slugify(string) {
    if (!string) {
      return ''
    }
    return string
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '')
      .replace(/\s+/g, '-')
  },

  pluralize(string) {
    return plural(string)
  },
  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (word.toUpperCase())).replace(/\s+/g, '')
  },
}
Vue.prototype.$stringUtils = StringUtils
window.stringUtils = StringUtils
export default StringUtils
