import Vue from 'vue'
import StringUtils from "@/libs/strings"
const ArrayUtils = {
    range(start, stop, step) {
        return Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step))
    }
}
Vue.prototype.$arrayUtils = ArrayUtils
window.arrayUtils = ArrayUtils
export default ArrayUtils
