export default {
    namespaced: true,
    state: {
        atendimento: [],
    },
    getters: {
        atendimento: state => state.atendimento,
    },
    mutations: {
        // eslint-disable-next-line no-shadow
        SET_ATENDIMENTO(state, payload) {
            state.atendimento = payload
        },
    },
    actions: {
        getAtendimento: async ({ commit }, id) => {
            try {
                const data = await window.http.get(`atendimentos/${id}`)
                commit('SET_ATENDIMENTO', data)
                return data
            } catch (error) {
                return error
            }
        },
        setAtendimento: ({ commit }, atendimento) => {
            commit('SET_ATENDIMENTO', atendimento)
        }
    },

}
