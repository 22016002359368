<template>
  <b-form-group
    :label="label"
    :label-for="name"
    class="mb-1"
  >
    <validation-provider
      v-slot="{ errors }"
      ref="myinput"
      :name="label"
      :vid="label"
      :rules="'date_format:DD/MM/YYYY|'+rules"
    >
      <b-input-group
        :state="errors.length > 0 ? false:null"
      >
        <b-form-input
          v-model="display"
          v-mask="'##/##/####'"
          debounce="500"
          v-bind="$attrs"
          :disabled="disabled"
          :state="errors.length > 0 ? false:null"
          :placeholder="placeholder"
        />
        <b-input-group-append v-if="!disabled">
          <b-form-datepicker

            v-model="inputValue"
            size="sm"
            v-bind="$attrs"
            button-only
            right
            bottom
            :disabled="disabled"
            :state="errors.length > 0 ? false:null"
            button-variant="outline-secondary"
            locale="pt-BR"
            style="height: 99%!important;"
            v-on="$listeners"
            @context="onContext"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { dateFormat, required } from '@core/utils/validations/validations'
import { ValidationProvider } from 'vee-validate'
import moment from 'moment-timezone'
import {
  BFormDatepicker, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'I2FormInputDate',
  components: {
    ValidationProvider,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,

  },
  props: {
    value: {
      type: [String],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    inputValue: null,
    errors: [],
    display: null,
    required,
    dateFormat,
  }),
  watch: {
    inputValue(val) {
      // eslint-disable-next-line eqeqeq
      if (val == '') {
        this.$emit('input', val)
        return
      }
      const date = String(moment(val).format('DD/MM/YYYY'))
      this.$emit('input', date)
    },
    display(val) {
      if (val === '') {
        this.$emit('input', '')
        return
      }
      if (val) {
        const formatedDated = moment(val, 'DD/MM/YYYY', true)
        if (formatedDated.isValid()) {
          if (formatedDated.format('DD/MM/YYYY') !== this.value) {
            this.$emit('input', formatedDated.format('DD/MM/YYYY'))
          }
        }
      }
    },
    value(val) {
      if (val === '' || val === null) {
        this.display = ''
        return
      }
      this.display = moment(val, 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
  },
  mounted() {

  },
  methods: {
    onContext(ctx) {
      if (!this.display) {
        this.display = this.value
      }
      if (ctx.selectedYMD && ctx.selectedYMD.length) {
        const date = String(moment(ctx.selectedYMD).format('L'))
        this.display = date
      }
    },
  },
}
</script>

<style>

</style>
