import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import { ValidationObserver } from 'vee-validate'
import I2FormInputDate from '@/components/i2/forms/elements/I2FormInputDate.vue'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import I2Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import PageHeader from '@/components/i2/layout/PageHeader.vue'
import STextDivider from '@/components/i2/layout/TextDivider'
import SAutoComplete from '@/components/i2/forms/elements/SAutoComplete'
import HotKeys from '@/components/i2/content/HotKeys.vue'
import I2FormCheckbox from "@/components/i2/forms/elements/I2FormCheckbox.vue"

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(I2FormInputDate.name, I2FormInputDate)
Vue.component(I2FormInput.name, I2FormInput)
Vue.component(I2FormVueSelect.name, I2FormVueSelect)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component(I2Grid.name, I2Grid)
Vue.component(I2SideBar.name, I2SideBar)
Vue.component(PageHeader.name, PageHeader)
Vue.component(STextDivider.name, STextDivider)
Vue.component(SAutoComplete.name, SAutoComplete)
Vue.component(HotKeys.name, HotKeys)
Vue.component(I2FormCheckbox.name, I2FormCheckbox)
