import Vue from 'vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import '@/libs/toastification'
import VueToast from 'vue-toast-notification'
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-bootstrap.css'

Vue.use(VueToast)

class Notify {
  $vm = Vue

  success(text) {
    this.$vm.$toast.success(text, { position: 'top-right', duration: 2000 })
  }

  error(text) {
    this.$vm.$toast.error(text, { position: 'top-right', duration: 2000 })
  }
}

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
const notify = new Notify()
Vue.prototype.$notify = notify
export default Notify
