import Vue from 'vue'

const currentUser = {

  getCurrentUser() {
    const userData = localStorage.getItem('userData')
    if (!userData) {
      return null
    }
    return JSON.parse(userData)
  },
}
Vue.prototype.$user = currentUser
window.user = currentUser
export default currentUser
